import { useCookieConsentContext } from "@use-cookie-consent/react";
import Head from "next/head";
import config from "../config";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import useHotjar from "../hooks/useHotjar";

export const ThirdPartyScripts = () => {
  const { consent } = useCookieConsentContext();
  useGoogleAnalytics();
  useHotjar();
  // Edit src/config/scripts if you want to add scripts.
  const scriptTags = React.useMemo(() => {
    return Object.entries(config.scripts).map(([key, script]) => {
      const props = script.attributes ?? {};
      const code = script.script;
      return (
        <Head key={key}>
          <script
            key={`script-${key}`}
            {...props}
            dangerouslySetInnerHTML={{
              __html: code,
            }}
          ></script>
        </Head>
      );
    });
  }, []);

  if (!consent.firstParty || !consent.thirdParty) {
    return null;
  }

  return (
    <React.Fragment>
      {scriptTags}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MN3CFLL"
          height="0"
          width="0"
          style={{ display: "none", visibility: "hidden" }}
        />
      </noscript>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
          src="https://px.ads.linkedin.com/collect/?pid=2765346&fmt=gif"
        />
      </noscript>
    </React.Fragment>
  );
};
