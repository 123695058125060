import React from "react";
import "swiper/dist/css/swiper.min.css";
import "ventura-slider/dist/tiny-slider.css";
import "../assets/css/app.css";
import "../assets/css/flexboxgrid.css";
import "../assets/webfonts/webfont.css";
import useScrollTop from "../hooks/useScrollTop";

import { CookieConsentProvider } from "@use-cookie-consent/react";
import { ThemeProvider } from "styled-components";
import { CookieConsent } from "../molecules/CookieConsent";
import { ThirdPartyScripts } from "../molecules/ThirdpartyScripts";
import { BugsnagErrorBoundary } from "../services/Bugsnag";

const theme = {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

const App = ({ Component, pageProps }) => {
  useScrollTop();

  return (
    <BugsnagErrorBoundary>
      <ThemeProvider theme={theme}>
        <CookieConsentProvider>
          <ThirdPartyScripts />
          <Component {...pageProps} />
          <CookieConsent />
        </CookieConsentProvider>
      </ThemeProvider>
    </BugsnagErrorBoundary>
  );
};

export default App;
