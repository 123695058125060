import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: process.env.BUGSNAG_KEY || "SOME_FAKE_KEY",
  plugins: [new BugsnagPluginReact()],
  onError: (report) => {
    if (report.errorMessage === "Network Error") {
      report.ignore();
    }

    if (report.originalError instanceof Object) {
      report.addMetadata("response", report.originalError.response || "");
      report.addMetadata("error", report.originalError);
    }
  },
});

export const BugsnagErrorBoundary = Bugsnag.getPlugin(
  "react"
).createErrorBoundary(React);

export { Bugsnag };
