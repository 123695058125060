import { useCookieConsentContext } from "@use-cookie-consent/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import ReactGA from "react-ga";

const useGoogleAnalytics = () => {
  const { asPath } = useRouter();
  const { consent } = useCookieConsentContext();

  useEffect(() => {
    if (
      !process.env.GOOGLE_ANALYTICS_ID ||
      !consent.firstParty ||
      !consent.thirdParty
    ) {
      return;
    }
    ReactGA.initialize(process.env.GOOGLE_ANALYTICS_ID, {
      debug: process.env.NODE_ENV === "development",
    });
  }, [consent.firstParty, consent.thirdParty]);

  useEffect(() => {
    if (
      !process.env.GOOGLE_ANALYTICS_ID ||
      !consent.firstParty ||
      !consent.thirdParty
    ) {
      return;
    }

    ReactGA.pageview(asPath);
  }, [asPath, consent.firstParty, consent.thirdParty]);
};

export default useGoogleAnalytics;
