import React from "react";

const Checkmark = ({ className }) => (
  <svg
    width="48px"
    height="36px"
    viewBox="0 0 48 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="noun_checkmark_1273238" fillRule="nonzero">
        <polygon
          id="Path"
          points="41.9531 0.4063 18.5156 24.75002 5.3593 14.06251 0.2969 20.28132 16.2969 33.28132 19.1719 35.59372 21.7031 32.93752 47.7031 5.9375"
        />
      </g>
    </g>
  </svg>
);

// SVGs should be responsive and dont have hardcode width and height
export const CheckmarkAlt = ({ className }) => (
  <svg
    viewBox="0 0 48 36"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="noun_checkmark_1273238" fillRule="nonzero">
        <polygon
          id="Path"
          points="41.9531 0.4063 18.5156 24.75002 5.3593 14.06251 0.2969 20.28132 16.2969 33.28132 19.1719 35.59372 21.7031 32.93752 47.7031 5.9375"
        />
      </g>
    </g>
  </svg>
);

export default Checkmark;
