import React, { useState } from "react";
import styled from "styled-components";
import Button from "../atoms/Button";
import Container from "../atoms/Container";
import Title from "../atoms/Title";
import { CheckmarkAlt } from "../atoms/icons/Checkmark";
import { color } from "../templates/ui";

import { useCookieConsentContext } from "@use-cookie-consent/react";
import { Close } from "../atoms/icons/Close";

const StyledContainer = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  padding: 30px 0;
  background-color: #050505;
  z-index: 99;
`;

const StyledWrapper = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media only screen and (min-width: 991px) {
    flex-direction: row;
  }
`;
const StyledCheckmark = styled(CheckmarkAlt)`
  fill: #fff;
`;

const StyledClose = styled(Close)`
  fill: #fff;
`;

const StyledbuttonGroup = styled.div`
  display: grid;
  gap: 1rem;
`;

const StyledLink = styled.a`
  text-decoration: none;
  transition: all 0.1s ease-in-out;
  color: ${color.body.base};

  &:hover {
    color: ${color.primary.base};
  }

  &:focus {
    outline: none;
  }
`;

const TextWrapper = styled.div`
  margin-bottom: 20px;
  margin-right: 30px;

  @media only screen and (min-width: 991px) {
    margin-bottom: 0;
  }
`;

const Description = styled.span`
  display: block;
  font-size: 15px;
  color: #c6c6c6;

  @media only screen and (min-width: 1250px) {
    min-width: 540px;
  }
`;

export const CookieConsent = () => {
  const {
    acceptAllCookies,
    declineAllCookies,
    consent,
  } = useCookieConsentContext();
  const [rejected, setRejected] = useState(false);

  // Cookies are accepted or rejected
  if (rejected || consent.firstParty || consent.thirdParty) return null;

  const handleReject = () => {
    declineAllCookies();
    setRejected(true);
  }

  return (
    <StyledContainer>
      <StyledWrapper>
        <TextWrapper>
          <Title tag="h4" color="white" hasStripe={true}>
            Cookieverklaring
          </Title>
          <Description>
            Deze website maakt gebruik van{" "}
            <StyledLink href="/cookeiverklaring">cookies</StyledLink> om uw
            gebruikerservaring te verbeteren. Door op "Accepteren" te klikken,
            gaat u akkoord met het gebruik van alle cookies.
          </Description>
        </TextWrapper>
        <StyledbuttonGroup>
          <Button onClick={acceptAllCookies} icon={<StyledCheckmark />}>
            Accepteren
          </Button>
          <Button
            variant="secondary"
            onClick={handleReject}
            icon={<StyledClose />}
          >
            Weigeren
          </Button>
        </StyledbuttonGroup>
      </StyledWrapper>
    </StyledContainer>
  );
};
