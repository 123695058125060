import { useCookieConsentContext } from "@use-cookie-consent/react";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";

const useHotjar = () => {
  const { consent } = useCookieConsentContext();
  useEffect(() => {
    if (
      !process.env.HOTJAR_ID ||
      !process.env.HOTJAR_SNIPPET_VERSION ||
      !consent.firstParty ||
      !consent.thirdParty
    ) {
      return;
    }

    hotjar.initialize(
      process.env.HOTJAR_ID,
      process.env.HOTJAR_SNIPPET_VERSION
    );
  }, [consent.firstParty, consent.thirdParty]);
};

export default useHotjar;
