import { useEffect } from "react";
import { useRouter } from "next/router";

const useScrollTop = () => {
  const { asPath } = useRouter();

  useEffect(() => {
    document.querySelector("#__next").scrollTop = 0;
  }, [asPath]);
};

export default useScrollTop;
