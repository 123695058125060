/*
 * Used to add tracking scripts in a more manageable way.
 *
 * Syntax:
 *
 * const scripts = {
 *  <scriptName>: {
 *      script: <scriptCode>,
 *      attributes: {
 *          <someAttributeForScriptTag>: <value>
 *      }
 *  }
 *}
 * Attributes is optional. If you just need some JavaScript code, you can omit it.
 */

const scripts = {
  twitter: {
    script: `!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
// Insert Twitter Pixel ID and Standard Event data below
twq('init','o70xw');
twq('track','PageView');`,
  },
  googleTagManager: {
    script: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MN3CFLL');`
  },
  linkedIn: {
    script: `_linkedin_partner_id = "2765346", window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [], window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
  },
  linkedInPixelAds: {
    script: `!function () {
    var t = document.getElementsByTagName("script")[0], e = document.createElement("script");
    e.type = "text/javascript", e.async = !0, e.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js", t.parentNode.insertBefore(e, t);
}();`,
  },
  noClueWhatScriptThisIs: {
    script: `!function (l) {
    function e(e) {
        for (var r, t, n = e[0], o = e[1], u = e[2], f = 0, i = []; f < n.length; f++) t = n[f], p[t] && i.push(p[t][0]), p[t] = 0;
        for (r in o) Object.prototype.hasOwnProperty.call(o, r) && (l[r] = o[r]);
        for (s && s(e); i.length;) i.shift()();
        return c.push.apply(c, u || []), a();
    }

    function a() {
        for (var e, r = 0; r < c.length; r++) {
            for (var t = c[r], n = !0, o = 1; o < t.length; o++) {
                var u = t[o];
                0 !== p[u] && (n = !1);
            }
            n && (c.splice(r--, 1), e = f(f.s = t[0]));
        }
        return e;
    }

    var t = {}, p = {1: 0}, c = [];

    function f(e) {
        if (t[e]) return t[e].exports;
        var r = t[e] = {i: e, l: !1, exports: {}};
        return l[e].call(r.exports, r, r.exports, f), r.l = !0, r.exports;
    }

    f.m = l, f.c = t, f.d = function (e, r, t) {
        f.o(e, r) || Object.defineProperty(e, r, {enumerable: !0, get: t});
    }, f.r = function (e) {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {value: "Module"}), Object.defineProperty(e, "__esModule", {value: !0});
    }, f.t = function (r, e) {
        if (1 & e && (r = f(r)), 8 & e) return r;
        if (4 & e && "object" == typeof r && r && r.__esModule) return r;
        var t = Object.create(null);
        if (f.r(t), Object.defineProperty(t, "default", {
            enumerable: !0,
            value: r,
        }), 2 & e && "string" != typeof r) for (var n in r) f.d(t, n, function (e) {
            return r[e];
        }.bind(null, n));
        return t;
    }, f.n = function (e) {
        var r = e && e.__esModule ? function () {
            return e.default;
        } : function () {
            return e;
        };
        return f.d(r, "a", r), r;
    }, f.o = function (e, r) {
        return Object.prototype.hasOwnProperty.call(e, r);
    }, f.p = "/";
    var r = window.webpackJsonp = window.webpackJsonp || [], n = r.push.bind(r);
    r.push = e, r = r.slice();
    for (var o = 0; o < r.length; o++) e(r[o]);
    var s = n;
    a();
}([]);`,
  },
  hubspot: {
    attributes: {
      id: "hs-script-loader",
      src: "//js.hs-scripts.com/7754160.js",
      async: true,
      defer: true,
    },
  },
  hsforms: {
    attributes: {
      id: "hs-forms",
      src: "//js.hsforms.net/forms/shell.js",
    },
  },
};

export default scripts;
