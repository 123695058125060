import scripts from "./scripts";

const config = {
  coronaCTALink: process.env.CORONA_CTA_LINK,
  oldSoftwareCTALink: process.env.OLD_SOFTWARE_CTA_LINK,
  automateProcessesCTALink: process.env.AUTOMATE_PROCESSES_CTA_LINK,
  MVPBenefitsCTALink: process.env.MVP_BENEFITS_CTA_LINK,
  AWSCloudCTALink: process.env.AWS_CLOUD_CTA_LINK,
  headlessCMSCTALink: process.env.HEADLESS_CMS_CTA_LINK,
  laravelCTALink: process.env.LARAVEL_CTA_LINK,
  nativeOrPwaLink: process.env.NATIVE_OR_PWA_CTA_LINK,
  qualityCTALink: process.env.QUALITY_CTA_LINK,
  scripts,
};

export default config;
